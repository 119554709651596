import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'
import styles from './Breadcrumb.module.scss'
import Link from 'next/link'
import React from 'react'

const backArrow = (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.00007 11.1539L0.346191 6.50007L5.00007 1.84619L5.53182 2.37794L1.40969 6.50007L5.53182 10.6222L5.00007 11.1539Z" fill="white" />
  </svg>
)

interface IBreadcrumbProps {
  separator?: string
  pathNameToDisplayName: { [key: string]: string }
}

function Breadcrumb({ separator = '/', pathNameToDisplayName }: IBreadcrumbProps) {
  const { asPath } = useRouter()
  const paths = usePathname()
  type PathNameType = keyof typeof pathNameToDisplayName
  let pathNames = paths!.split('/').filter(path => path) as PathNameType[]
  if (!asPath.includes('sId=')) {
    pathNames = pathNames.slice(0, -1)
  }

  return (
    <div className={styles['breadcrumb-container']}>
      <ul>
        {pathNames.map((link, index) => {
          const href = `/${pathNames.slice(0, index + 1).join('/')}`

          const itemLink = pathNameToDisplayName[link]

          return (
            <React.Fragment key={`${link}-${index}`}>
              <li>
                <Link href={href}>
                  {itemLink && <div>{index === 0 && backArrow}</div>}
                  <div>{itemLink}</div>
                </Link>
              </li>
              {pathNames.length !== index + 1 && separator}
            </React.Fragment>
          )
        })}
      </ul>
    </div>
  )
}

export default Breadcrumb
